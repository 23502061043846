/*! CREDIT: https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c#.v77rs2jif */

'use strict';

Object.defineProperty(exports, '__esModule', {
	value: true
});
exports['default'] = navbar;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _jquery = require('jquery');

var _jquery2 = _interopRequireDefault(_jquery);

var cssPrefix = function cssPrefix($el, rule, value, prValue) {
	['-webkit-', '-moz-', '-ms-', '-o-', ''].forEach(function (prefix) {
		$el.css(prefix + rule, (prValue ? prefix : '') + value);
	});
};

function navbar(_ref) {
	var navbarSelector = _ref.navbarSelector;
	var hidingClass = _ref.hidingClass;
	var _ref$pollInterval = _ref.pollInterval;
	var pollInterval = _ref$pollInterval === undefined ? 200 : _ref$pollInterval;

	(0, _jquery2['default'])(function () {
		var delta = 5;
		var $navbar = (0, _jquery2['default'])(navbarSelector);

		var didScroll = undefined;
		var lastScrollTop = 0;

		var navbarHeight = $navbar.outerHeight();

		if (!hidingClass) {
			cssPrefix($navbar, 'transition-property', 'transform', true);
		}

		(0, _jquery2['default'])(window).scroll(function () {
			return didScroll = true;
		});

		function hasScrolled() {
			var st = (0, _jquery2['default'])(window).scrollTop();

			// Make sure they scroll more than delta
			if (Math.abs(lastScrollTop - st) <= delta) {
				return;
			}

			// If they scrolled down and are past the navbar, add class .nav-up.
			// This is necessary so you never see what is "behind" the navbar.
			if (st > lastScrollTop && st > navbarHeight) {
				// Scroll Down
				if (hidingClass) {
					$navbar.addClass(hidingClass);
				} else {
					cssPrefix($navbar, 'transform', 'translateY(-100%)');
				}
			} else {
				// Scroll Up
				if (st + (0, _jquery2['default'])(window).height() < (0, _jquery2['default'])(document).height()) {
					if (hidingClass) {
						$navbar.removeClass(hidingClass);
					} else {
						cssPrefix($navbar, 'transform', 'translateY(0)');
					}
				}
			}

			lastScrollTop = st;
		}

		setInterval(function () {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, pollInterval);
	});
}

module.exports = exports['default'];

